import React from "react";
import styled from "styled-components";
import {
  THead,
  TrHead,
  Th as BaseTh,
  TBody,
  InnerCell,
  RotaCheckbox,
  RotaPlaceholder,
  RotaBox,
  RotaButtonIcon,
  iconNew
} from "@teamrota/rota-design";

import { usePreviewTimesheetDetails } from "./use-preview-timesheet-details";
import { RotaTableScrollable } from "./style";
import { Timesheet } from "./use-timesheets-with-stats";
import TimesheetDetailsMemberRow, {
  TimesheetDetailsMemberRowLoader
} from "./timesheet-details-member-row";

import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";

const { ChevronLeft, Expand, Contract } = iconNew;

const Th = styled(BaseTh)`
  font-weight: 500;
  font-size: 16px;
  background-color: ${({ theme }) => theme.neutral.lighter};
`;

const Box = styled(RotaBox)`
  margin: 32px;
  width: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 16px;
  align-items: center;
  padding: 16px 0px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: calc(100% - 72px);
`;

const PreviewInfo = styled.h5`
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 56px;
`;

const TimesheetDetailsLoader = () => {
  return (
    <Box>
      <HeaderWrapper>
        <RotaPlaceholder circle size={48} />
        <RotaPlaceholder size={24} maxWidth={180} />
        <RotaPlaceholder circle size={48} />
      </HeaderWrapper>
      <InnerWrapper>
        <RotaTableScrollable>
          <THead>
            <TrHead>
              <Th>
                <InnerCell>
                  <RotaCheckbox
                    isChecked={false}
                    isDisabled
                    onClick={() => {}}
                  />
                </InnerCell>
              </Th>
              <Th />
              <Th style={{ textAlign: "left", width: 266 }}>Name</Th>
              <Th>Rating</Th>
              <Th>In/Out</Th>
              <Th>Total hours</Th>
              <Th>Status</Th>
            </TrHead>
          </THead>
          <TBody>
            {Array.from({ length: 8 }).map((_, index) => (
              <TimesheetDetailsMemberRowLoader key={index} />
            ))}
          </TBody>
        </RotaTableScrollable>
      </InnerWrapper>
    </Box>
  );
};

interface Props {
  timesheet: Timesheet;
  showSummary: boolean;
  onClose: () => void;
  onToggleExpand: () => void;
  user: any;
  venueId: string;
}

export const PreviewTimesheetDetails = ({
  timesheet,
  showSummary,
  onClose,
  onToggleExpand,
  user
}: Props) => {
  const { timesheetMembers, loading, refetch } = usePreviewTimesheetDetails({
    id: timesheet.id
  });

  const subvenues = user?.account?.venues?.find(
    (venue: any) => venue.id === timesheet.venueId
  )?.subvenues;

  const hasSubvenues = subvenues?.length > 0;

  if (loading && !timesheetMembers.length) return <TimesheetDetailsLoader />;
  if (!timesheetMembers) return null;

  return (
    <Box>
      <HeaderWrapper>
        <RotaButtonIcon onClick={onClose}>
          <ChevronLeft />
        </RotaButtonIcon>
        {<Title>{timesheet.shiftName || ""}</Title>}
        <RotaButtonIcon onClick={onToggleExpand}>
          {showSummary ? <Expand /> : <Contract />}
        </RotaButtonIcon>
      </HeaderWrapper>
      <PreviewInfo>
        This is a preview. You will be able to approve timesheets shortly after
        the shift has ended.
      </PreviewInfo>
      <InnerWrapper>
        <RotaTableScrollable>
          <THead>
            <TrHead>
              <Th>
                <InnerCell>
                  <RotaCheckbox
                    isChecked={false}
                    isDisabled
                    onClick={() => {}}
                  />
                </InnerCell>
              </Th>
              <Th />
              <Th style={{ textAlign: "left", width: 266 }}>Name</Th>
              <Th style={{ minWidth: 200 }}>Cancellation</Th>
              <Th>In/Out</Th>
              <Th>Total hours</Th>
              {hasSubvenues && <Th>Sub-venue</Th>}
              <Th>Status</Th>
            </TrHead>
          </THead>
          <TBody>
            {timesheetMembers.map(timesheetMember => {
              return (
                <TimesheetDetailsMemberRow
                  timesheet={timesheet}
                  subvenues={subvenues}
                  key={timesheetMember.id}
                  timesheetMember={timesheetMember}
                  refetch={() => refetch()}
                  isPreview
                />
              );
            })}
          </TBody>
        </RotaTableScrollable>
      </InnerWrapper>
    </Box>
  );
};

export default getProfile(PreviewTimesheetDetails);
