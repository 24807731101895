import React from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import {
  Tr,
  Td,
  InnerCell,
  RotaCheckbox,
  RotaAvatar,
  RotaStatusLabel,
  RotaPlaceholder,
  useToast,
  RotaSelect
} from "@teamrota/rota-design";

import { InnerCellTime } from "./style";
import {
  getMemberStatusType,
  getMemberPinIcon,
  memberStatusTranslations,
  convertMinutesToTimeString,
  cancellationOptions,
  getCancellation,
  Cancellation,
  calculateCheckInOutTimes
} from "./utils";
import { TIMESHEET_MEMBER_STATUS } from "@teamrota/rota-common";
import { useUpdateTimesheetMember } from "./use-update-timesheet-member";
import { useUpdatePreviewTimesheetMember } from "./use-update-preview-timesheet-member";
import { Timesheet } from "./use-timesheets-with-stats";
import { TimesheetInternalStatus } from "./types";
import { useGetPayCalculationSetting } from "./use-get-acount-pay-calc-setting";

export interface TimesheetMember {
  id: string;
  name: string;
  memberAvatar: string;
  roleName: string;
  totalMinutes: number;
  totalPay: number;
  payRate: number;
  status: keyof typeof TIMESHEET_MEMBER_STATUS;
  checkInTime: string;
  checkOutTime: string;
  ratingOfMember: number;
  memberId: string;
  isNoShow: boolean;
  isTurnedAway: boolean;
  bookingId?: string;
  subvenueId?: string;
}

const Option = styled.option``;

export const TimesheetDetailsMemberRowLoader = () => {
  return (
    <Tr>
      <Td>
        <InnerCell>
          <RotaCheckbox isDisabled isChecked={false} onClick={() => {}} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder circle size={48} />
        </InnerCell>
      </Td>
      <Td style={{ width: 266 }}>
        <InnerCell>
          <RotaPlaceholder />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder />
        </InnerCell>
      </Td>
      <Td>
        <InnerCellTime>
          <RotaPlaceholder />
        </InnerCellTime>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder />
        </InnerCell>
      </Td>
    </Tr>
  );
};

interface Props {
  timesheet: Timesheet;
  timesheetMember: TimesheetMember;
  isChecked?: boolean;
  isPreview?: boolean;
  onToggleOneItem?: (id: string) => void;
  refetch?: () => Promise<any>;
  refetchTimesheetDetails?: () => Promise<any>;
  subvenues?: any;
}

const TimesheetDetailsMemberRow = ({
  timesheet,
  timesheetMember,
  isChecked = false,
  isPreview,
  onToggleOneItem = () => {},
  refetch,
  refetchTimesheetDetails,
  subvenues
}: Props) => {
  const {
    memberId,
    name,
    memberAvatar,
    checkInTime,
    checkOutTime,
    totalMinutes,
    status,
    isNoShow,
    isTurnedAway,
    subvenueId
  } = timesheetMember;
  const isShiftFinished = moment().isAfter(timesheet.shiftEndTime);
  const isLateCheckIn =
    !checkInTime ||
    moment(checkInTime).isAfter(timesheet.shiftStartTime, "minutes");

  const isLateCheckOut =
    !checkOutTime ||
    moment(checkOutTime).isAfter(timesheet.shiftEndTime, "minutes");

  const { showToast } = useToast();

  const { checkIn, checkOut } = calculateCheckInOutTimes(
    useGetPayCalculationSetting(),
    timesheet,
    checkInTime,
    checkOutTime
  );

  const { updateTimesheetMember, loading } = useUpdateTimesheetMember({
    onCompleted: () => {
      showToast("Timesheet member row was updated");
    }
  });

  const {
    updatePreviewTimesheetMember,
    loadingPreview
  } = useUpdatePreviewTimesheetMember({
    onCompleted: () => {
      showToast("Timesheet member row was updated");
      refetch && refetch();
      refetchTimesheetDetails && refetchTimesheetDetails();
    }
  });

  const subvenueOptions = [
    { label: "None", value: "" },
    ...(subvenues
      ?.map((subvenue: any) => ({
        label: subvenue.name,
        value: subvenue.id
      }))
      .sort((a: any, b: any) => a.label.localeCompare(b.label)) || [])
  ];

  const hasSubvenues = subvenueOptions?.length > 1;

  const isDisabled =
    loading || timesheet.status === TimesheetInternalStatus.LOCKED;

  return (
    <>
      <Tr isActive={isChecked}>
        <Td>
          <InnerCell>
            <RotaCheckbox
              isChecked={isChecked}
              isDisabled={isPreview || isDisabled}
              onClick={() => onToggleOneItem(memberId)}
            />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>
            <RotaAvatar src={memberAvatar} />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell style={{ textAlign: "left" }}>{`${name}`}</InnerCell>
        </Td>
        <Td>
          <InnerCell>
            <RotaSelect
              loading={loading || loadingPreview}
              disabled={timesheet.status === TimesheetInternalStatus.LOCKED}
              value={getCancellation({ isNoShow, isTurnedAway })}
              onChange={({ target: { value } }) => {
                if (isPreview) {
                  updatePreviewTimesheetMember({
                    id: timesheetMember.bookingId || "",
                    isNoShow: value === Cancellation.NO_SHOW,
                    isTurnedAway: value === Cancellation.TURNED_AWAY
                  });
                } else {
                  updateTimesheetMember({
                    id: timesheetMember.id,
                    isNoShow: value === Cancellation.NO_SHOW,
                    isTurnedAway: value === Cancellation.TURNED_AWAY
                  });
                }
              }}
            >
              {cancellationOptions.map(option => (
                <Option value={option.value}>{option.label}</Option>
              ))}
            </RotaSelect>
          </InnerCell>
        </Td>
        <Td>
          <InnerCellTime>
            {getMemberPinIcon({
              status,
              isLateCheckIn,
              isLateCheckOut: isShiftFinished && isLateCheckOut,
              isChecked
            })}
            {`${checkIn} - ${checkOut}`}
          </InnerCellTime>
        </Td>
        <Td>
          <InnerCell>
            {isPreview ? "N/A" : convertMinutesToTimeString(totalMinutes)}
          </InnerCell>
        </Td>
        {hasSubvenues && (
          <Td>
            <InnerCell>
              <RotaSelect
                loading={loading || loadingPreview}
                disabled={!isPreview}
                value={subvenueId ?? timesheet.subvenueId ?? ""}
                onChange={e => {
                  const selectedSubvenueId = e.target.value || null;
                  if (isPreview) {
                    updatePreviewTimesheetMember({
                      id: timesheetMember.bookingId || "",
                      subvenueId: selectedSubvenueId
                    });
                  }
                }}
              >
                {subvenueOptions.map((option: any) => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
              </RotaSelect>
            </InnerCell>
          </Td>
        )}
        <Td>
          <InnerCell>
            <RotaStatusLabel
              type={getMemberStatusType(status)}
              style={{ margin: "auto" }}
            >
              {memberStatusTranslations[status]}
            </RotaStatusLabel>
          </InnerCell>
        </Td>
      </Tr>
    </>
  );
};

export default TimesheetDetailsMemberRow;
