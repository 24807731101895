import React from "react";
import styled from "styled-components";
import Tabs from "~/src/containers/modals/components/tabs";
import { STYLE_CONSTS } from "~/src/styles/config.style";
import EditRoles from "./edit-roles";

const StyledTabs = styled(Tabs)`
  ${Tabs.ContentWrapper} {
    margin: 0;
  }
  ${Tabs.Tab} {
    margin-left: 0;
    margin-right: 0;
    font-size: ${STYLE_CONSTS.FONT_SMALL};
  }
  ${Tabs.TabNav} {
    column-gap: ${STYLE_CONSTS.UNIT_4};
  }
`;

const CalculatedUncalculatedRolesLists = ({
  accountId,
  calculatedRoles,
  uncalculatedRoles,
  roleRates,
  uncalculatedRoleRates,
  tags,
  isPartner
}) => {
  if (!uncalculatedRoles.length) {
    return (
      <EditRoles
        accountId={accountId}
        roles={calculatedRoles}
        roleRates={roleRates}
        tags={tags}
        isPartner={isPartner}
      />
    );
  }

  const tabs = [
    {
      name: "Calculated",
      content: (
        <EditRoles
          accountId={accountId}
          roles={calculatedRoles}
          roleRates={roleRates}
          tags={tags}
          isPartner={isPartner}
        />
      )
    },
    {
      name: "Uncalculated",
      content: (
        <EditRoles
          accountId={accountId}
          roles={uncalculatedRoles}
          roleRates={roleRates}
          uncalculatedRoleRates={uncalculatedRoleRates}
          tags={tags}
          isPartner={isPartner}
          disableEditRole
          isUncalculated
        />
      )
    }
  ];

  return <StyledTabs tabs={tabs} defaultTab="Calculated" />;
};

export default CalculatedUncalculatedRolesLists;
