import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { TimesheetMember } from "./timesheet-details-member-row";

const TIMESHEET_DETAILS = gql`
  query PreviewTimesheetsDetails($id: ID!, $limit: Int!, $offset: Int!) {
    account {
      id
      previewTimesheetDetails(id: $id, limit: $limit, offset: $offset) {
        id
        name
        memberAvatar
        roleName
        totalMinutes
        totalPay
        payRate
        status
        checkInTime
        checkOutTime
        status
        ratingOfMember
        bookingId
        isNoShow
        isTurnedAway
        subvenueId
      }
    }
  }
`;

interface Params {
  id: string;
}

export const usePreviewTimesheetDetails = ({ id }: Params) => {
  const { data, loading, error, refetch } = useQuery(TIMESHEET_DETAILS, {
    fetchPolicy: "cache-and-network",
    variables: { id, offset: 0, limit: 100 },
    onError: error => {
      throw error;
    }
  });

  const timesheetMembers: TimesheetMember[] =
    data?.account?.previewTimesheetDetails || [];

  return {
    timesheetMembers,
    loading,
    error,
    refetch
  };
};
